<template>
  <div>
    <p class="mb-3">Consider the following system that is at equilibrium:</p>

    <p class="mb-4 pl-5">
      <chemical-latex content="2 A(g) + B(s) <=> 2 C(g)" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) Which direction will the equilibrium shift if
        <stemble-latex :content="'$[\\text{A}]$'" />
        is decreased?
      </p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-3">
        b) Which direction will the equilibrium shift if
        <stemble-latex :content="'$[\\text{B}]$'" />
        is increased?
      </p>
      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-3">
        c) Which direction will the equilibrium shift if
        <stemble-latex :content="'$[\\text{C}]$'" />
        is increased?
      </p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-3">
        d) Which direction will the equilibrium shift if the volume of the reaction vessel is
        increased (at constant temperature)?
      </p>

      <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question156',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {condition: '$\\text{Shift left (form more reactants)}$', value: '1'},
        {condition: '$\\text{Shift right (form more products)}$', value: '2'},
        {condition: '$\\text{No change}$', value: '3'},
      ],
    };
  },
};
</script>
